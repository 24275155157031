(function (document) {
    'use strict';

    var tableSort = (function () {
      function _getSortableValue(tr, index) {
        // TODO(mkchand1): Add the ability to retrieve a specific data- attribute from
        // the element that can have a value that is more sortable than the displayed value
        return tr.children[index].innerText || tr.children[index].textContent;
      }

      function _comparer(index, sortAscending) {
        return function (a, b) {
          return function (valueA, valueB) {
            if (valueA !== '' && valueB !== '' && !isNaN(valueA) && !isNaN(valueB)) {
              return valueA - valueB
            } else {
              return valueA.toString().localeCompare(valueB);
            }
          }(_getSortableValue(sortAscending ? a : b, index), _getSortableValue(sortAscending ? b : a, index));
        }
      }

      function _onSortEvent(e) {
        const th = e.currentTarget;
        const tbody = th.closest('table').querySelector('tbody');
        const rows = Array.from(tbody.querySelectorAll('tr'));
        const headerIndex = Array.from(th.parentNode.children).indexOf(th);

        // TODO(mkchand1): Need a way to store the sort direction for each column. Right
        // now this is global so will use whatever the last column sorted was, even for a
        // different column
        const sortAscending = document.asc = !document.asc;

        rows.sort(_comparer(headerIndex, sortAscending));

        for (var i = 0; i < rows.length; i++) {
          tbody.appendChild(rows[i]);
        }
      }

      return {
        init: function () {
          document.asc = true;
          var sortableHeaders = document.querySelectorAll('th.sortable');

          for (var i = 0; i < sortableHeaders.length; i++) {
            sortableHeaders[i].addEventListener('click', _onSortEvent);
          }
        }
      };
    })();

    document.addEventListener('readystatechange', function () {
      if (document.readyState === 'complete') {
        tableSort.init();
      }
    });
  })(document);
