﻿
(function (document, navigator) {

	let runToTop = (function () {

	  var wHeight = window.innerHeight;
	  var minHeight = wHeight - 150;
	  window.onscroll = function () { scrollFunction() };
	  let scrollFunction = () => {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
		  document.getElementById("top-link-block").classList.add('pulse');
		} else {
		  document.getElementById("top-link-block").classList.remove('pulse');
		}
	  }

	  return {
		init: function () {
		  document.getElementById('top-link-block').addEventListener('click', function (event) {
			event.preventDefault();
			document.body.scrollTop = 0; /* For Safari */
			document.documentElement.scrollTop = 0; /* For Chrome, Firefox, IE and Opera */
		  })
		}
	  };
	})();

	document.addEventListener('readystatechange', function () {
	  if (document.readyState === 'complete') {
		runToTop.init();
	  }
	});

  })(document, navigator);
